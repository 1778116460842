export default {
	/*     apiKey: "AIzaSyAN3KaTc2V10OQDyN24Y_FV2N-mHIhyz9s",
    authDomain: "semil-connect.firebaseapp.com",
    databaseURL: "https://semil-connect.firebaseio.com",
    projectId: "semil-connect",
    storageBucket: "semil-connect.appspot.com",
    messagingSenderId: "144254082568",
    appId: "1:144254082568:web:d87ecebafca72b045d3bbd",
    measurementId: "G-W7753SC8RX" */
	apiKey: "AIzaSyDbKG9-Fa66eGjWLPkkzD6MBRLr1oQ1Ylo",
	authDomain: "teste-semil.firebaseapp.com",
	databaseURL: "https://teste-semil-default-rtdb.firebaseio.com",
	projectId: "teste-semil",
	storageBucket: "teste-semil.appspot.com",
	messagingSenderId: "665074556786",
	appId: "1:665074556786:web:8937d820ed42dd69c0c7eb",
	measurementId: "G-ZZ77S0T1QY",
};
